//import logo from './logo.svg';
//import './App.css';

// src/App.js
import React from 'react';
import InteractiveTree from './Tree';

function App() {
  return (
    <div className="App">
      <h1>Interactive Tree App</h1>
      <InteractiveTree />
    </div>
  );
}

export default App;

