// Tree.js
import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';

const initialData = {
  name: 'Root',
  description: 'Root Node Description',
  children: [
    {
      name: 'Node 1',
      description: 'Description for Node 1',
      children: [
        { name: 'Node 1.1', description: 'Description for Node 1.1' },
        { name: 'Node 1.2', description: 'Description for Node 1.2' },
      ],
    },
    {
      name: 'Node 2',
      description: 'Description for Node 2',
      children: [
        { name: 'Node 2.1', description: 'Description for Node 2.1' },
        { name: 'Node 2.2', description: 'Description for Node 2.2' },
      ],
    },
  ],
};

const InteractiveTree = () => {
  const [treeData, setTreeData] = useState(initialData);
  const [tooltipContent, setTooltipContent] = useState(null);

  const NodeRenderer = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle
        r={15}
        onClick={() => toggleNode && toggleNode(nodeDatum)}
        onMouseOver={() => setTooltipContent(nodeDatum.description)}
        onMouseOut={() => setTooltipContent(null)}
      />
      <text>{nodeDatum.name}</text>
    </g>
  );

  useEffect(() => {
    // You can dynamically update the treeData as needed here
    // For example, fetching data from an API
  }, []);

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <Tree
        data={treeData}
        orientation="vertical"
        translate={{ x: 250, y: 50 }}
        renderCustomNodeElement={(rd3tProps) => (
          <NodeRenderer {...rd3tProps} />
        )}
      />
      {tooltipContent && (
        <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default InteractiveTree;

